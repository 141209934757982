import { addChain } from '@/shared/utils/addChain';
import { setGlobalState } from '@/store';
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers5/react';

const Footer = () => {
  const { address, chainId } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();
  const isAppChainConnected = chainId === Number(process.env.CHAIN_ID);
  const handleAddBahamut = () => {
    if (!address) {
      setGlobalState('redirect', null);
      open();
    } else {
      addChain();
    }
  };

  return (
    <footer className="w-[100%] px-[80px] text-white py-[40px] bg-[#000000] z-[-1]">
      <div className="main_container flex justify-between items-center">
        <p className="text-[14px] leading-[15.4px] font-archivo font-regular text-[#FFFFFF] opacity-[40%]">
          ©2024 8 Legends Marketplace
        </p>
        <div className="flex gap-[30px] items-center ">
          <p className="text-[14px] leading-[15.4px] font-archivo font-regular text-[#FFFFFF] opacity-[40%]">
            Privacy Policy
          </p>
          <p className="text-[14px] leading-[15.4px] font-archivo font-regular text-[#FFFFFF] opacity-[40%]">
            Terms of Service
          </p>
          {!isAppChainConnected && (
            <button
              className="
          w-[180px] py-[16px] px-[12px] flex flex-col items-center bg-primary rounded-[18px] text-white  text-[14px] leading-[16px] font-semiBold shadow-primary border border-transparent hover:bg-[#1d1f24]  hover:text-primary hover:border-1 hover:border-primary hover:shadow-none transition duration-300 ease-in-out
          "
              onClick={handleAddBahamut}
            >
              {address ? 'Switch to' : 'Add'} Bahamut
            </button>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

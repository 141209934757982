import { Contract, ethers } from 'ethers';
import abi from '../../abis/src/Auction/Auction.json';
import nftAbi from '../../abis/src/Nft/Nft.json';

const CONTRACT_ADDRESS = process.env.AUCTION_CONTRACT_ADDRESS;
const toWei = (num) => ethers.utils.parseEther(num?.toString());

const getEthereumContract = async (walletProvider) => {
  try {
    const provider = await new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    const contract = await new Contract(CONTRACT_ADDRESS, abi, signer);

    return contract;
  } catch (e) {
    return e;
  }
};

const getEthereumCollectionContract = async (address, walletProvider) => {
  try {
    const provider = await new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    const contract = await new Contract(address, nftAbi, signer);

    return contract;
  } catch (e) {
    return e;
  }
};

export const createAuction = async ({
  token_id,
  collection_address,
  price,
  buyoutPrice,
  duration,
  biddable,
  walletProvider,
}) => {
  const contract = await getEthereumContract(walletProvider);
  const collectionContract = await getEthereumCollectionContract(
    collection_address,
    walletProvider,
  );

  const appprove = await collectionContract.approve(CONTRACT_ADDRESS, token_id);
  await appprove.wait();

  const transaction = await contract.createAuction(
    collection_address,
    token_id,
    toWei(price),
    toWei(buyoutPrice),
    duration,
    biddable,
  );

  await transaction.wait();
  return true;
};

export const createBid = async ({
  nftId,
  collectionAddress,
  price,
  walletProvider,
}) => {
  const contract = await getEthereumContract(walletProvider);
  const transaction = await contract.placeBid(collectionAddress, nftId, {
    value: toWei(price),
  });
  await transaction.wait();
};

export const claimToken = async ({
  nftId,
  collectionAddress,
  walletProvider,
}) => {
  const contract = await getEthereumContract(walletProvider);
  const transaction = await contract.claimToken(collectionAddress, nftId);
  await transaction.wait();
};

export const cancelAuction = async ({
  nftId,
  collectionAddress,
  walletProvider,
}) => {
  const contract = await getEthereumContract(walletProvider);
  const transaction = await contract.cancelAuction(collectionAddress, nftId);

  await transaction.wait();
};

export const buyAuctionedItem = async ({
  nftId,
  collectionAddress,
  buyoutPrice,
  walletProvider,
}) => {
  const contract = await getEthereumContract(walletProvider);
  const transaction = await contract.buyAuctionedItem(
    collectionAddress,
    nftId,
    { value: toWei(buyoutPrice) },
  );
  await transaction.wait();
};

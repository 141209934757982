import { setGlobalState, useGlobalState } from '@/store';
import { useRouter } from 'next/navigation';
// import { useRouter } from 'next/navigation';

const NFTCreatedSuccessfully = () => {
  const [boxModal] = useGlobalState('boxModal');
  const closeModal = () => {
    setGlobalState('boxModal', 'scale-0');
    setGlobalState('boxModalType', null);
  };

  const [successData] = useGlobalState('nftSuccessData');
  const router = useRouter();

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen flex items-center
        justify-center bg-[#1D1F24CC] bg-opacity-30 transform
        transition-transform duration-300 z-50 ${boxModal}`}
    >
      <div className="w-[374px] p-[20px] bg-neutral-800/opacity-80 rounded-[20px] border border-white-30 backdrop-blur-[60px]">
        <div className="relative">
          <img
            src={successData?.image}
            className="w-[100%] h-[334px] rounded-[16px] mb-[40px] object-cover"
            alt=""
          />
          <div className="absolute inset-0 flex justify-center items-end bottom-[-30px]">
            <img
              src="/png/successIcon.svg"
              className="w-[60px] h-[60px] rounded-full shadow-2px"
              alt=""
            />
          </div>
        </div>
        <div className="text-center font-semibold text-[30px] font-['Archivo'] leading-[30px] mb-[10px] text-[#FE7762]">
          Successful
        </div>
        <div className="text-center mb-[20px]">
          <span className="text-center font-medium text-[18px] font-['Archivo'] leading-[18px] text-white">
            Now you own{' '}
          </span>
          <span className="font-medium text-[18px] font-['Archivo'] leading-[18px] text-[#FE7762]">
            {successData?.name}
          </span>
        </div>
        <div className="hover:text-white flex gap-[10px] flex-col ">
          <a
            onClick={() => {
              router.push('/profile');
            }}
            href="/profile"
            className="p-[20px] bg-white rounded-[18px] font-semibold text-[16px] font-spaceGrotesk leading-[16px] text-black hover:bg-[#0E0E0E] hover:border-2 hover:border-white hover:cursor-pointer hover:text-white border-2 border-white transition ease-out duration-300"
          >
            View my assets
          </a>
          <a
            onClick={() => {
              closeModal();
            }}
            href="/create-nft"
            className="p-[20px] bg-black rounded-[18px] font-semibold text-[16px] font-spaceGrotesk leading-[16px] text-white hover:border-2 hover:border-white hover:cursor-pointer hover:text-white border-2 border-black transition ease-out duration-300"
          >
            Create another
          </a>
        </div>
      </div>
    </div>
  );
};

export default NFTCreatedSuccessfully;

import React, { useEffect, useState } from 'react';
import { getGlobalState, setGlobalState, useGlobalState } from '@/store';
// import { useRouter } from 'next/navigation';
// import useAddTestnetToNetwork from '@/hooks/useAddTestnetToNetwork';
import { useAtom } from 'jotai';
import { isAppChainConnectedAtom } from '@/shared/atoms/isAppChainConnected';

const CHAIN_ID = Number(process.env.CHAIN_ID);

const ConnectWallet = () => {
  // const { isLoading, addTestnetToNetwork } = useAddTestnetToNetwork();
  const [boxModal] = useGlobalState('boxModal');
  // const [redirect] = useGlobalState('redirect');
  const connectedAccount = getGlobalState('connectedAccount');
  const [checkedWallet, setCheckedWallet] = useState(false);
  // const platform = getGlobalState('platform');
  // const router = useRouter();
  const provider = null;
  const [, seIsAppChainConnected] = useAtom(isAppChainConnectedAtom);

  const onCloseModal = () => {
    setGlobalState('boxModal', 'scale-0');
    setGlobalState('boxModalType', null);
  };
  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      onCloseModal();
    }
  };

  const onCheckWallet = async () => {};

  //TODO: remove if not needed
  // const test2 = async () => {
  //   await provider?.request({
  //     method: 'wallet_switchEthereumChain',
  //     params: [{ chainId: process.env.CHAIN_ID.toString(16) }],
  //   });
  // };

  useEffect(() => {
    if (provider?.on) {
      const handleChainChanged = async (_hexChainId) => {
        if (Number(_hexChainId) !== CHAIN_ID) {
          seIsAppChainConnected(false);
        } else {
          seIsAppChainConnected(true);
        }
      };

      provider.on('chainChanged', handleChainChanged);
    }
  }, [provider]);

  useEffect(() => {
    connectedAccount && onCloseModal();
  }, [connectedAccount]);

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen flex items-center
justify-center bg-black bg-opacity-50 transform
transition-transform duration-300 z-50 ${boxModal} `}
      onClick={handleBackdropClick}
    >
      {checkedWallet ? (
        <div className="flex flex-col justify-between w-[349px] gap-[28px] p-[20px] items-center rounded-[20px] border border-whiteBorder backdrop-blur backdrop-blur-3xl z-50">
          <div className={`w-full flex flex-row justify-between items-center `}>
            <div
              className={`flex w-[24px] h-[24px] justify-center items-center cursor-pointer`}
              onClick={() => setCheckedWallet(false)}
            >
              <img
                src="/svg/arrowLeft.svg"
                className="text-white w-[14px] h-[16px] items-center"
                alt=""
              />
            </div>
            <div
              className={`flex w-[24px] h-[24px] justify-center items-center cursor-pointer`}
              onClick={onCloseModal}
            >
              <img
                src="/svg/closeIcon.svg"
                className="text-white w-[14px] h-[14px] items-center"
                alt=""
              />
            </div>
          </div>
          <div className="w-[126px] h-[126px] rounded-[50%] flex items-center justify-center bg-white border">
            <div>
              <img src="/png/MetaMask_Fox 1.png" />
            </div>
          </div>

          <div className="w-full px-[28.5px] flex flex-col gap-[12px] items-center text-center">
            <p className="w-full  text-white font-archivo text-[20px] leading-[20px] font-semiBold">
              Waiting for MetaMask
            </p>
            <div>
              <span className="w-full  text-white font-archivo text-[14px] leading-[19.6px] font-regular ">
                For the best experience, connect only one wallet at a time
              </span>
            </div>
          </div>
          <div className="w-full py-[23px] text-center rounded-[18px] bg-secondaryButton">
            <span className="text-white font-archivo text-[16px] leading-[18.24px] font-medium ">
              Connecting...
            </span>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-between w-[349px] gap-[67.7px] p-[20px] items-center rounded-[20px] border border-whiteBorder backdrop-blur backdrop-blur-3xl z-50">
          <div className={`w-full flex flex-row justify-between items-center`}>
            <div className={`w-[24px] h-[24px]`} />
            <p className="text-white font-archivo text-[20px] leading-[30px] font-medium">
              Connect wallet
            </p>
            <div
              className={`flex w-[24px] h-[24px] justify-center items-center cursor-pointer`}
              onClick={() => {
                onCloseModal();
              }}
            >
              <img
                src="/svg/closeIcon.svg"
                className="text-white w-[14px] h-[14px] items-center"
              />
            </div>
          </div>
          <img className="w-[100px] h-[50px]" src="/png/logo.png" />
          <div className="w-full flex flex-col items-center gap-[20px]">
            {/* <div
              className='w-full flex flex-row items-center px-[24px] py-[12px] bg-white cursor-pointer rounded-[18px] gap-[10px]'
              onClick={() => onCheckWallet(true)}
            >
              <div className='w-[40px] h-[40px]'>
                <img src={walletconnect} alt='walletconnect' />
              </div>
              <p className='text-black font-spaceGrotesk text-[16px] leading-[16px] font-semiBold'>
                WalletConnect
              </p>
            </div> */}
            <div
              className="w-full flex flex-row items-center px-[24px] py-[12px] bg-white cursor-pointer rounded-[18px] gap-[10px]"
              onClick={() => onCheckWallet()}
            >
              <div className="w-[40px] h-[40px]">
                <img src="/png/MetaMask_Fox 1.png" />
              </div>
              <p className="text-black font-spaceGrotesk text-[16px] leading-[16px] font-semiBold">
                MetaMask
              </p>
            </div>
            <div className="width-full text-center text-whiteBorder font-archivo text-[14px] leading-[19.6px] font-regular">
              <span>
                {' '}
                By clicking Sign Up, you agree to the
                <span className=" underline decoration-solid">
                  {' '}
                  Terms of Use{' '}
                </span>
                and the
                <span className=" underline decoration-solid">
                  {' '}
                  Privacy Policy{' '}
                </span>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectWallet;

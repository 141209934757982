import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

class LoadingController {
  static setRef(ref) {
    this.ref = ref;
  }
  static hide() {
    this.ref.hide();
  }
  static load(promise, trying) {
    this.ref.load();
    return promise
      .then((res) => {
        this.ref.hide();
        return res;
      })
      .catch((err) => {
        if (trying) {
          return this.ref.trying(trying);
        } else {
          this.ref.hide();
        }
        throw err;
      })
      .finally(() => {});
  }

  static Provider() {
    return (
      <LoadingPopup
        ref={(ref) => {
          LoadingController.setRef(ref);
        }}
      ></LoadingPopup>
    );
  }
}

const LoadingPopup = forwardRef((props, ref) => {
  const [isShow, show] = useState(false);
  const [isTrying, setTrying] = useState(false);
  const tryingCallback = useRef(null);
  useImperativeHandle(ref, () => ({
    load: () => {
      show(true);
    },
    hide: () => {
      show(false);
    },
    trying: (trying) => {
      setTrying(true);
      tryingCallback.current = trying;
    },
  }));
  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen flex items-center
      justify-center bg-black bg-opacity-50 transform
      transition-transform duration-300 z-50 ${
        isShow ? 'scale-100' : 'scale-0'
      } `}
    >
      <div className="flex flex-col justify-between w-[349px] gap-[28px] p-[20px] items-center rounded-[20px] border border-whiteBorder backdrop-blur backdrop-blur-3xl z-50">
        <div className="loading-popup">
          <div className="flex flex-col justify-center items-center gap-[30px]">
            <img src="/png/logo.png" alt="" />
            <button
              disabled={!isTrying}
              className={`w-[216px] py-[20px] px-[24px] flex flex-col items-center bg-primery ${
                isTrying
                  ? 'hover:bg-[#1d1f24]  hover:text-primery hover:border-1 hover:border-primery hover:shadow-none'
                  : 'bg-opacity-20'
              } text-white  text-[16px] leading-[16px] font-semiBold shadow-primery border border-transparent font-spaceGrotesk bg-primery rounded-[18px] transition duration-300 ease-in-out`}
              onClick={() => {
                if (tryingCallback.current) {
                  tryingCallback.current();
                }
              }}
            >
              <p className="">
                {isTrying ? 'Try one more time' : 'Processing...'}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

LoadingPopup.displayName = LoadingPopup;

export default LoadingController;

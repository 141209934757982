'use client';
import { useEffect, useState } from 'react';
import { Header } from '@/components/Header/Header';
import PlaceBid from '@/components/PlaceBid';
import { Toaster } from 'react-hot-toast';
import { useRouter } from 'next/navigation';
import { isWallectConnected } from '@/services/blockchain';
import { setGlobalState, store, useGlobalState } from '@/store';
import OfferItem from '@/components/OfferItem';
import ChangePrice from '@/components/ChangePrice';
import CustomModal from '@/components/CustomModal/CustomModal';
import Footer from '@/components/Footer/Footer';
import LoadingController from '@/components/LoadingPopup/LoadingPopup';
import { defaultConfig } from '@web3modal/ethers5';
import { createWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { Provider } from 'react-redux';

const projectId = process.env.WALLET_CONNECT_PROJECT_ID;

const CHAIN_ID = Number(process.env.CHAIN_ID);

const mainnet = {
  chainId: CHAIN_ID,
  name: process.env.NETWORK_NAME,
  currency: 'FTN',
  explorerUrl: process.env.NEXT_PUBLIC_CHAIN_EXPLORER,
  rpcUrl: process.env.RPC_URL,
};

// 3. Create a metadata object
const metadata = {
  name: '8 Legends',
  description:
    'Decentralized marketplace for story-based NFTs. Create, trade and collect interactive NFTs. Powered by Bahamut blockchain.',
  icons: [
    'https://gateway.pinata.cloud/ipfs/QmXtGMyAy3PHqdVufDjNCkAUt6iyVdswo6H4LsuuEntFQ3',
  ],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
});

// 5. Create a AppKit instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: false, // Optional - defaults to your Cloud configuration
});

function App({ children }) {
  const [userAgent, setUserAgent] = useState(null);
  const [auction] = useGlobalState('auction');
  const [platform, setPlatform] = useState('web');
  const { address } = useWeb3ModalAccount();

  const [redirect] = useGlobalState('redirect');
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const agent = window.navigator.userAgent.toLowerCase();
      setUserAgent(agent);
    }
  }, []);

  useEffect(() => {
    const connectApp = async (address) => {
      setGlobalState('connectedAccount', address);

      // eslint-disable-next-line no-undef
      const balanceHash = await ethereum.request({
        method: 'eth_getBalance',
        params: [address, 'latest'],
      });
      const balance = parseInt(balanceHash, 16) / Math.pow(10, 18);
      setGlobalState('balance', balance);

      if (redirect) {
        router.push(redirect);
      }
    };

    if (address) {
      connectApp(address);
    }
  }, [address, redirect, router]);

  const handlePlatform = (userAgent) => {
    if (/iPad|iPhone|iPod/i.test(userAgent)) {
      setPlatform('ios');
      setGlobalState('platform', 'ios');
    } else if (/android/i.test(userAgent)) {
      setPlatform('android');
      setGlobalState('platform', 'android');
    } else {
      setPlatform('web');
      setGlobalState('platform', 'web');
    }
  };

  useEffect(() => {
    handlePlatform(userAgent);
    try {
      isWallectConnected();
    } catch (error) {
      return error;
    }
  }, [userAgent]);

  return (
    <main className="w-full overflow-hidden px-4">
      <Provider store={store}>
        <Header />
        <div className="to-gray-900 bg-center subpixel-antialiased">
          <div className="min-h-[calc(100vh-197px)] pb-[120px]">{children}</div>

          <CustomModal platform={platform} />
          <LoadingController.Provider />
          {auction ? (
            <>
              <PlaceBid />
              <OfferItem />
              <ChangePrice />
            </>
          ) : null}
          <Toaster
            toastOptions={{
              error: {
                duration: 5000,
              },
              success: {
                duration: 5000,
              },
            }}
          />
        </div>
        <Footer />
      </Provider>
    </main>
  );
}
export default App;

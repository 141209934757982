import { Box, Modal } from '@mui/material';
import { setGlobalState, useGlobalState } from '@/store';
import { fromWei } from '@/services/blockchain';
import { buyAuctionedItem } from '@/services/Auction/auction-blockchain';
import toast from 'react-hot-toast';
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from '@web3modal/ethers5/react';

export const BuyNowNftModal = () => {
  const [balance] = useGlobalState('balance');
  const [auction] = useGlobalState('nftCardData');
  const buyoutPrice = fromWei(auction.fixed_price);
  // const [connectedAccount] = useGlobalState('connectedAccount');
  const { walletProvider } = useWeb3ModalProvider();
  const { isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();
  const handleNFTpurchase = async () => {
    if (!isConnected) {
      open();
      return;
    }
    try {
      toast.loading('Buying NFT');
      await buyAuctionedItem({
        nftId: auction?.token_id,
        collectionAddress: auction?.collection_address,
        buyoutPrice: buyoutPrice,
        walletProvider,
      });
      toast.dismiss();
      toast.success('NFT bought successfully');
    } catch (error) {
      toast.dismiss();
      toast.error('Error buying NFT');
    }

    handleCloseBuyModal();
  };

  const handleCloseBuyModal = () => {
    setGlobalState('boxModalType', null);
  };

  return (
    <Modal open={true} onClose={handleCloseBuyModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          border: `1px solid #605B59`,
          borderRadius: '20px',
          backgroundColor: 'rgba(29, 31, 36, 0.4)',
          padding: '30px',
          color: 'white',
          maxWidth: '476px',
          outline: 'none',
        }}
        className="backdrop-blur-[50px]"
      >
        <div className="flex flex-col gap-[20px] items-start">
          <div
            className="absolute top-[30px] right-[30px] cursor-pointer"
            onClick={handleCloseBuyModal}
          >
            <img src="/svg/close.svg" className="w-[14px] h-[14px]" />
          </div>
          <div className="text-[30px] font-semibold">Buy Now</div>
          <div className="text-[16px]">
            Once the transaction is confirmed, the NFT will be sent to your
            wallet instantly.
          </div>
          <div className="flex gap-[14px] items-center p-[12px] bg-[#1D1F24] rounded-[18px] text-[20px] w-full">
            {' '}
            <div className="w-[72px] h-[72px]">
              {' '}
              <img
                src={auction?.image}
                style={{ objectFit: 'cover' }}
                className="object-cover w-full h-full rounded-[14px]"
              />{' '}
            </div>
            {auction?.name}
          </div>
          <div className="w-full ">
            <div className="flex justify-between w-full">
              <div className="text-[16px] opacity-50">Your balance</div>
              <div className="flex gap-[6px] items-center">
                <img
                  src="/png/Networks Crypto white.png"
                  className="w-[20px] h-[20px] mr-[6px]"
                />
                {balance} FTN
              </div>
            </div>
            <div
              className="h-[1px] w-full mt-[14px] mb-[14px]"
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
            />
            <div className="flex justify-between w-full items-center">
              <div className="text-[16px] opacity-50">Buyout Price</div>
              <div className="flex gap-[6px] items-center text-[30px] leading-[30px]">
                <img
                  src="/png/Networks Crypto white.png"
                  className="w-[30px] h-[30px] mr-[6px]"
                />{' '}
                {buyoutPrice} FTN
              </div>
            </div>
          </div>
          <button
            type="button"
            className="bg-white rounded-[18px] text-center py-[20px] grow font-spaceGrotesk font-semibold text-[16px] leading-[16px] text-[#0E0E0E] border-2 border-white hover:bg-[#0E0E0E] hover:border-white hover:text-white transition duration-300 ease-in-out w-full"
            onClick={handleNFTpurchase}
          >
            Confirm
          </button>
        </div>
      </Box>
    </Modal>
  );
};

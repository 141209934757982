import * as React from 'react';
import { useEffect, useState } from 'react';

const SelectCalendarTime = (props) => {
  const [openTimeModal, setOpenTimeModal] = useState(false);

  const hours = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
  ];
  const minutes = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '60',
  ];

  useEffect(() => {
    props.chosenDate.minute && props.chosenDate.hour && setOpenTimeModal(false);
  }, [props.chosenDate.minute, props.chosenDate.hour]);

  return (
    <div className="text-white flex justify-between items-center relative mb-[20px]">
      <div className="font-archivo text-white font-semibold text-[20px] leading-[30px] ">
        Select time
      </div>
      <div className="flex items-center">
        <label className="font-archivo text-white font-semibold text-[20px] leading-[30px] mr-[20px]">
          {props.chosenDate.hour}:{props.chosenDate.minute}
        </label>
        <div
          className={`w-[44px] h-[44px] rounded-[100%] border-2 ${
            openTimeModal
              ? 'border-[#FE7762]'
              : 'border-white border-opacity-40'
          }  border-solid flex justify-center items-center ${
            openTimeModal && 'bg-[#FE7762]'
          }`}
          onClick={() => setOpenTimeModal(!openTimeModal)}
        >
          <img src="/png/clock-fill.svg" />
        </div>
        {openTimeModal && (
          <div className="absolute top-[55px] right-0 text-white flex gap-[4px] justify-between w-[130px] h-[192px] bg-[#1d1f2466] border-2 border-[#FFFFFF4D] rounded-[16px] p-[4px] backdrop-blur-[50px] bg-[#1D1F24]/opacity-40">
            <div className="overflow-y-scroll w-full time-with-scroll">
              {hours.map((hour, index) => {
                return (
                  <div
                    key={index}
                    className="font-spaceGrotesk text-white font-semibold text-[14px] leading-[21px] py-[7.5px] rounded-[12px] text-center w-full hover:bg-[#FFFFFF1A] hover:cursor-pointer"
                    onClick={() => {
                      props.setChosenDate((prevState) => ({
                        ...prevState,
                        hour: hour,
                      }));
                    }}
                  >
                    {hour}
                  </div>
                );
              })}
            </div>
            <div className="overflow-y-scroll w-full time-with-scroll">
              {minutes.map((minute, index) => {
                return (
                  <div
                    key={index}
                    className="font-spaceGrotesk text-white font-semibold text-[14px] leading-[21px] py-[7.5px] rounded-[12px] text-center w-full hover:bg-[#FFFFFF1A] hover:cursor-pointer"
                    onClick={() => {
                      props.setChosenDate((prevState) => ({
                        ...prevState,
                        minute: minute,
                      }));
                    }}
                  >
                    {minute}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectCalendarTime;

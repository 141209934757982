import React from 'react';

const HeaderInput = () => {
  return (
    <div className="w-[486px] px-[12px] py-[6px] rounded-[38px] bg-dark_gray flex flex-row justify-between items-center">
      <div className="flex flex-row gap-[6px]">
        <img src="/svg/loopIcon.svg" />
        <input
          type="text"
          className="w-[284px] text-white font-archivo text-[14px] leading-[21px] font-regular bg-transparent border-none p-0"
          placeholder="Search adventure"
        />
      </div>
      <div className="w-[140px] flex flex-row items-center justify-between py-[12px] px-[20px] bg-page_bg rounded-[42px]">
        <span className="text-white font-spaceGrotesk text-[14px] leading-[21px] font-semiBold">
          All Items
        </span>
        <img src="/svg/selectArrow.svg" />
      </div>
    </div>
  );
};

export default HeaderInput;
